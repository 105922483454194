import * as R from 'ramda'
import { ISlakteriListByRegion, ISlakteriState } from './types'
import { STORE_NAME } from './index'
import { IStoreState } from '../../reducers/types'
import { createSelector } from 'reselect'

export const slakteriLenses = {
  data: R.lensPath([STORE_NAME, 'data']),
  index: R.lensPath([STORE_NAME, 'index']),
}

export const groupAndSortByRegion = R.pipe(
  R.groupBy((slakteri) =>
    slakteri.orgTreePath
      ? slakteri.orgTreePath.includes('M25000_EJBOrgUnit')
        ? 'Region Nord'
        : slakteri.orgTreePath.includes('M22000_EJBOrgUnit')
        ? 'Region Øst'
        : slakteri.orgTreePath.includes('M23000_EJBOrgUnit')
        ? 'Region Sør og Vest'
        : slakteri.orgTreePath.includes('M24000_EJBOrgUnit')
        ? 'Region Midt'
        : slakteri.orgTreePath.includes('M21000_EJBOrgUnit')
        ? 'Region Stor-Oslo'
        : slakteri.orgTreePath.includes('M1_EJBOrgUnit')
        ? 'Hovedkontoret'
        : 'Ukjent Region'
      : 'Ukjent Region',
  ),
  R.toPairs,
  R.sortBy(R.prop('0')),
)

export const slakteriSelectors = {
  getSlakteriGroupedByRegion: createSelector(
    (state: IStoreState) => R.view(slakteriLenses.data)(state),
    (data): ISlakteriListByRegion =>
      R.pipe(
        R.values,
        R.pluck('data'),
        R.defaultTo([]),
        groupAndSortByRegion,
      )(data),
  ),

  getSlakteriLoadingStatus: (state: IStoreState): ISlakteriState =>
    state.slakterier,
}
