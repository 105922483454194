import { slakterierActions, slakterierActionTypes } from '../index'
import { combineEpics, ofType, StateObservable } from 'redux-observable'
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators'

// Types
import { Observable, of } from 'rxjs'
import { AnyAction } from 'redux'
import { IStoreState } from '../../../reducers/types'

// api
import { ICommonApi } from '../../../api'

// helpers
import { formatSlakteriRespons } from './helpers'

export const fetchSlakterier =
  (commonApi: ICommonApi) =>
  (state: IStoreState): Observable<AnyAction> =>
    of(state).pipe(
      commonApi.get('/api/slakterier?size=1000', state),
      map((resp: any) =>
        formatSlakteriRespons(resp._embedded.tilsynsobjektList),
      ),
      map((data) => slakterierActions.fetchListOk(data)),
    )

export const fetchSlakterierEpic =
  (commonApi: ICommonApi) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<IStoreState>,
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(slakterierActionTypes.FETCH_LIST),
      withLatestFrom(state$),
      switchMap(([, state]) =>
        fetchSlakterier(commonApi)(state).pipe(
          catchError((err) => of(slakterierActions.fetchListFail(err.message))),
        ),
      ),
    )

export default (commonApi: ICommonApi) =>
  combineEpics(fetchSlakterierEpic(commonApi))
